export const strict = false;

export const state = () => ({
  ua: '',
  mobile: false,
  countries: {list: []},
});


export const getters = {
  isMobile(state) {
    return state.mobile;
  },
};


export const mutations = {
  setUa(state, value) {
    state.ua = value;
  },
  setMobile(state, value) {
    state.mobile = value;
  },
};

export const actions = {};
